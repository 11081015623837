<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="tableSizeChange"
            @handleCurrentChange="tableCurrentChange" :currentPage="pageInfo.currentPage" :total="pageInfo.total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="companyName" align="center" label="企业名称" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="联系人" show-overflow-tooltip />
                <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column prop="shareUserName" align="center" label="分享人" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="130">
                    <template slot-scope="scope">
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" 
                            @confirm="disassociate(scope.row.linkId)" icon="el-icon-info" icon-color="red"
                            title="确认要取消该名片关联？">
                            <el-button slot="reference" type="text" style="color: #fd634e">
                                取消关联
                            </el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
    </div>
</template>

<script>


import { getParkCardShareLinkList, delParkCardShareLink } from "@/api/parkManagement"
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "parkCardTable",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: [],
            pageInfo: {
                currentPage: 1,
                total: 0
            },
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            parkId: 0
        };
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        },
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.pageInfo.currentPage = 1
            this.getList();
        }
    },
    filters: {

    },
    created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    methods: {
        disassociate(linkId) {
            delParkCardShareLink({ linkId }).then(res => {
                this.$message({
                    type: "info",
                    message: "取消完成"
                })
                this.getList();
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 列表API配置 */
        /**@method 搜索 */
        searchFun() {
            this.pageInfo.currentPage=1;
            this.getList();
        },
        /**@method 切换行 */
        tableSizeChange(val) {
            this.pageInfo.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        tableCurrentChange(val) {
            this.pageInfo.currentPage = val;
            this.getList();
        },
        /**@method 获取列表 */
        async getList(payload) {

            let params = {
                parkId: this.parkId,
                pageNum: this.pageInfo.currentPage,
                pageSize: this.pageInfo.pageSize
            };
            try {
                this.loading = true;
                let res = await getParkCardShareLinkList(params);
                this.loading = false;

                const { data: { list, total, pageNum } } = res;
                let tableData = [];
                for (let row of list) {
                    tableData.push(row);
                }
                this.pageInfo = {
                    currentPage: pageNum,
                    total: total
                }
                this.tableData = tableData;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>